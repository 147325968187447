<template>
  <v-col cols="12" md="10" offset-md="1" lg="8" offset-lg="2">
    <v-card>
      <v-toolbar>
        <v-toolbar-title>Study Abroad Application: {{ appData.programTerm || 'Pending' }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu bottom offset-y>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" text>
              {{ status === 'Pending' ? 'Pending Approval' : status }}
              <v-icon right>fal fa-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item @click="setStatus('Returned')">
              <v-list-item-title>Make Changes to my application</v-list-item-title>
            </v-list-item>
            <v-list-item @click="setStatus('Cancelled')">
              <v-list-item-title>Cancel my application</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
      <v-card-text>
        Your study abroad application has been submitted. Below are the offices which will need to approve your application. Please follow up with these offices to ensure that you complete everything that is needed.
        <div v-if="appData.international && !('scannedPassport' in appData || appData.scannedPassport)" style="margin-top:1em">
          Because you are applying for an international program, you must verify that you have a valid passport. Please scan your passport and upload a copy here.
          <v-file-input ref="passportRef" v-model="passport" label="Passport" :rules="[ v => !!v || 'Passport is required for international programs' ]"></v-file-input>
          <v-btn text color="primary" @click="upload()" :disabled="!passport || isUploading">{{ isUploading ? 'Uploading...' : 'Upload Passport' }}</v-btn>
        </div>
        <div v-else-if="appData.scannedPassport" style="margin-top:1em">
          Your passport has been uploaded.
        </div>
        <v-alert v-if="!healthApproved" color="error" icon="fal fa-exclamation-triangle" border="left" elevation="2" class="mb-2" colored-border>You need to schedule an appointment with Health Services to be evaluated prior to being cleared to study abroad. Because of the different requirements for each program, please ensure that you schedule your appointment soon and make sure they know it is for a study abroad application and where you are planning to go.</v-alert>
        <v-alert v-if="!priesthillApproved" color="error" icon="fal fa-exclamation-triangle" border="left" elevation="2" colored-border>You need to schedule an appointment with PriestHill Counseling Services prior to being cleared to study abroad.</v-alert>
      </v-card-text>
      <v-tabs v-model="selectedTab">
        <v-tab>Status</v-tab>
        <v-tab>Messages</v-tab>
      </v-tabs>
      <v-tabs-items v-model="selectedTab">
        <v-tab-item>
          <v-list three-line>
            <v-list-item>
              <v-list-item-avatar>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" :color="guardianSignature.date ? 'success' : 'info'" style="flex:none;margin-right:10px">{{ guardianSignature.date ? 'fal fa-check-circle' : 'fal fa-question-circle' }}</v-icon>
                  </template>
                  <span>{{ guardianSignature.date ? 'Signed' : 'Pending Guardian Signature' }}</span>
                </v-tooltip>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Parent/Guardian Signature</v-list-item-title>
                <v-list-item-subtitle v-if="guardianSignature.date">{{ guardianSignature.name }} signed the application on {{ stringFormatDate(guardianSignature.date) }}</v-list-item-subtitle>
                <v-list-item-subtitle v-else>Please contact your parent/guardian and have them complete the signature. An email was sent to {{ guardianSignature.email }}. Click the envelope to the right to resend the email.</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action v-if="!guardianSignature.date">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" :disabled="emailSending" :icon="!emailSending" @click="emailGuardian">
                      <span v-if="emailSending">Sending...</span>
                      <v-icon v-else>fas fa-envelope</v-icon>
                    </v-btn>
                  </template>
                  <span>Resend Email to Parent/Guardian at {{ guardianSignature.email }}</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
            <v-list-item v-for="(item, index) in approvals" :key="'listitem-' + index">
              <v-list-item-avatar>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" :color="item.approved ? 'success' : 'info'" style="flex:none;margin-right:10px">{{ item.approved ? 'fal fa-check-circle' : 'fal fa-question-circle' }}</v-icon>
                  </template>
                  <span>{{ item.approved ? 'Approved' : 'Pending Approval' }}</span>
                </v-tooltip>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ item.position }}</v-list-item-title>
                <v-list-item-subtitle>Responsibilities: {{ item.description }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action class="d-sm-none">
                <v-dialog v-model="item.infoDialog" width="400">
                  <template v-slot:activator="{ on: dialogOn }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on: tooltipOn }">
                        <v-btn icon v-on="{ ...dialogOn, ...tooltipOn }">
                          <v-icon color="primary">fas fa-question-circle</v-icon>
                        </v-btn>
                      </template>
                      <span>More Info</span>
                    </v-tooltip>
                  </template>
                  <v-card>
                    <v-card-title>{{ item.position }}</v-card-title>
                    <v-card-text>Responsibilities for your application: {{ item.description }}</v-card-text>
                    <v-card-actions>
                      <v-btn text @click="item.infoDialog = false">Close</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-list-item-action>
              <v-list-item-action>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon @click="viewMessages(index)" v-on="on">
                      <v-badge :content="item.unreadMessages" :value="item.unreadMessages" color="error" overlap>
                        <v-icon>fal fa-envelope</v-icon>
                      </v-badge>
                    </v-btn>
                  </template>
                  <span>View/Send Message {{ item.unreadMessages ? '(' + item.unreadMessages + ' unread)' : '' }}</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-tab-item>
        <v-tab-item>
          <v-card>
            <v-card-text>
              <v-select v-model="selectedDepartment" :items="approvals" item-value="department" item-text="position" label="View/Send Messages with Department" outlined></v-select>
              <div v-if="selectedDepartment !== ''">
                <home-app-message-timeline v-if="messages.length > 0" :messages="messages" :app-username="appData.username"></home-app-message-timeline>
                <p v-else>There are no messages to display.</p>
                <v-textarea v-model="message" label="Message to Send" outlined></v-textarea>
                <v-btn text color="primary" @click="sendMessage()">Send Message</v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-col>
</template>
<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { stringFormatDate } from '../../../helpers/formatters'
import axios from 'axios'

export default {
  components: {
    HomeAppMessageTimeline: () => import('@/components/student/study-abroad/HomeAppMessageTimeline')
  },
  props: {
    appId: {
      type: String,
      required: true
    }
  },
  setup (props, { root, emit }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const size = computed(() => root.$vuetify.breakpoint)
    const status = ref('')
    const appData = ref({
      name: '',
      programTerm: ''
    })
    const selectedTab = ref(0)

    const service = root.$feathers.service('student/study-abroad')

    const guardianSignature = computed(() => appData.value.guardianSignature || { date: false, email: '' })

    const approvals = ref([])
    const healthApproved = computed(() => {
      const row = approvals.value.filter(({ department }) => department === 'Health Services' || department === 'Health & Counseling Services')
      if (row.length === 0) return false
      const { approved } = row[0]
      return approved || false
    })
    const priesthillApproved = computed(() => {
      const row = approvals.value.filter(({ department }) => department === 'PriestHill Counseling Services')
      if (row.length === 0) return false
      const { approved } = row[0]
      return approved || false
    })
    const ignoreLoad = ref(false)

    function load () {
      if (ignoreLoad.value) return
      ignoreLoad.value = true
      service.get(props.appId).then((data) => {
        if ('status' in data) {
          status.value = data.status
        } else if ('submitted' in data && data.submitted) {
          status.value = 'Pending'
          if ('approvals' in data) {
            let approved = true
            for (let i = 0; i < data.approvals.length; i++) {
              if (!data.approvals[i].approved) {
                approved = false
                break
              }
            }
            if (approved) {
              status.value = 'Approved'
            }
          }
        } else {
          status.value = 'Unsubmitted'
        }
        appData.value = data
        messages.value = data.messages

        let departments = [
          { position: 'Academic Advisor', department: 'Advisor', description: 'Check course equivalencies in major, confirm academic readiness', messagesVisible: false },
          { position: 'Registrar or Associate Registrar', department: 'Records', description: 'Check course equivalencies in CORE/electives and verify good academic standing', messagesVisible: false, email: 'recordsoffice@covenant.edu' },
          { position: 'Assistant Director of Financial Aid', department: 'Financial Aid', description: 'Provide student with email or printout of aid package and inform student of additional financial options s/he may have', messagesVisible: false, email: 'financialaid@covenant.edu' },
          { position: 'Associate Dean of Students', department: 'Student Development', description: 'Verify good behavioral/judicial standing and review housing policies upon return', messagesVisible: false, email: 'studentdevelopment@covenant.edu' },
          { position: 'Director of Health Services', department: 'Health Services', description: 'Confirm vaccinations, up-to-date health insurance on file, and determine physical health readiness based on program requirements', messagesVisible: false, email: 'healthservices@covenant.edu' },
          { position: 'Assistant Director of Counseling Services', department: 'PriestHill Counseling Services', description: 'Discuss and consult with students on emotional preparations to spend a semester abroad', messagesVisible: false, email: 'priesthill@covenant.edu' },
          { position: 'Director of Global Education', department: 'World Languages', description: 'Confirm all paperwork is completed, passport is on file, and visa process is underway', messagesVisible: false, email: 'aubrianna.pennington@covenant.edu' }
        ]
        approvals.value = departments.map((dep) => {
          const appr = data.approvals.find(({ department }) => department === dep.department)
          if (appr) {
            const unread = messages.value.filter(({ department, readUsername }) => department === dep.department && !readUsername.includes(user.value.username))
            return { ...dep, ...appr, unreadMessages: unread.length }
          }
        })
        ignoreLoad.value = false
      })
    }

    watch(() => props.appId, () => {
      load()
    })
    onMounted(() => {
      service.on('patched', (data) => {
        if (data._id === props.appId) {
          load()
        }
      })
    })

    const passport = ref(null)
    const passportRef = ref(null)
    const isUploading = ref(false)

    function upload () {
      isUploading.value = true
      let formData = new FormData()
      formData.append('filename', appData.value.name + ' - ' + appData.value.programTerm)
      formData.append('file', passport.value)
      formData.append('contentType', passport.value.type)
      formData.append('folder', '1qvJWRHoPDXmL0fZPR97g1ULBAbDSKYzU')
      let token = window.localStorage['feathers-jwt']
      const basePath = process.env.VUE_APP_API_URL + process.env.VUE_APP_API_PATH.replace('/socket.io', '')
      axios.post(basePath + '/service/drive-upload', formData, { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } }).catch((e) => {
        let snackbar = { active: true, color: 'error', timeout: 6000, text: 'Error submitting: ' + e }
        root.$store.dispatch('main/snackbar', snackbar)
        isUploading.value = false
      }).then((res) => {
        service.patch(props.appId, { scannedPassport: res.data.id })
      })
    }

    const selectedDepartment = ref('')
    const selectedApprovalIndex = ref(-1)
    const messages = ref([])
    const filteredMessages = computed(() => messages.value.filter(({ department }) => department === selectedDepartment.value))
    const message = ref('')

    watch(selectedDepartment, () => {
      setTimeout(async () => {
        if (filteredMessages.value.length > 0) {
          const patch = { $push: { 'messages.$[elem].readUsername': user.value.username } }
          const arrayFilters = [{ 'elem.department': selectedDepartment.value, 'elem.readUsername': { $ne: user.value.username } }]
          try {
            await service.patch(props.appId, patch, { query: { arrayFilters } })
          } catch (e) {
            console.error(e)
            root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error marking messages as read: ' + e })
          }
        }
      }, 2000)
    })

    async function sendMessage () {
      const { username, preferred, last } = user.value
      let $push = {
        messages: {
          username,
          name: preferred + ' ' + last,
          date: new Date(),
          message: message.value,
          department: selectedDepartment.value,
          visibleToStudent: true,
          readUsername: [ username ]
        }
      }
      await service.patch(props.appId, { $push })
      message.value = ''

      const { email, username: u } = approvals.value.find(({ department }) => department === selectedDepartment.value)
      let to = []
      if (email) to.push(email)
      else to.push(u + '@covenant.edu')
      if (to.length > 0) {
        root.$feathers.service('system/email').create({
          from: 'report-email@covenant.edu',
          to,
          subject: 'New Study Abroad Application Message',
          html: 'A new message was added by ' + preferred + ' ' + last + ' to their study abroad application. To view and respond to this message, view their application here: <a href="https://portal.covenant.edu/student/study-abroad/' + props.appId + '">https://portal.covenant.edu/student/study-abroad/' + props.appId + '</a>.',
          text: 'A new message was added by ' + preferred + ' ' + last + ' to their study abroad application. To view and respond to this message, view their application here: https://portal.covenant.edu/student/study-abroad/' + props.appId + '.'
        })
      }
    }

    function viewMessages (itemIndex) {
      selectedDepartment.value = approvals.value[itemIndex].department
      selectedTab.value = 1
    }

    function setStatus (newStatus) {
      service.patch(props.appId, { status: newStatus })
      status.value = newStatus
      emit('statusChanged', newStatus)
    }

    const emailSending = ref(false)
    async function emailGuardian () {
      try {
        emailSending.value = true
        await root.$feathers.service('system/email').create({
          from: 'report-email@covenant.edu',
          to: guardianSignature.value.email,
          subject: 'Signature Needed for Study Abroad Application',
          html: 'Your student at Covenant College has started a study abroad application which requires your digital signature. Please click the link below to access the study abroad application. You will need the student\'s first name and the email address to which this email was sent to access it (along with the code that is embedded in the link).<br/><br/><a href="https://portal.covenant.edu/student/study-abroad/signature/' + props.appId + '">https://portal.covenant.edu/student/study-abroad/signarure/' + props.appId + '</a>'
        })
        root.$store.dispatch('main/snackbar', { color: 'success', text: 'Email sent successfully' })
      } catch (e) {
        root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error sending email to guardian: ' + e })
      }
      emailSending.value = false
    }

    return {
      selectedTab,
      size,
      status,
      appData,
      load,
      guardianSignature,
      approvals,
      healthApproved,
      priesthillApproved,
      passport,
      passportRef,
      isUploading,
      upload,
      selectedDepartment,
      selectedApprovalIndex,
      messages,
      message,
      sendMessage,
      viewMessages,
      setStatus,
      emailSending,
      emailGuardian,
      stringFormatDate
    }
  }
}
</script>
